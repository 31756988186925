import React from "react"
import { Link as IntlLink } from "gatsby-plugin-intl"
import { Box } from "rebass"

export const Wrapper = ({ ...props }) => (
  <Box
    width="100%"
    sx={{
      display: "grid",
      gap: "42px",
      mixBlendMode: 'color-dodge',
      gridTemplateColumns: [
        "repeat(auto-fit, minmax(140px, 1fr))",
        "repeat(auto-fit, minmax(150px, 1fr))",
      ],
      mt: 4,
      "& img, & svg": {
        filter: "grayscale(100%)",
        maxHeight: 90,
        maxWidth: 110,
        transition: ".2s filter",
        alignSelf: "center",
        justifySelf: "center",
        "&:hover": {
          filter: "grayscale(0%)",
        },
      },
    }}
    {...props}
  />
)

export function Link({ ...props }) {
  return (
    <IntlLink
      style={{
        textDecoration: "none",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
      {...props}
    />
  )
}