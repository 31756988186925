import { Link } from "gatsby"
import React from "react"
import { Box, Flex, Image as ImageRebass } from "rebass"

export const Image = props => (
  <ImageRebass alignSelf="center" justifySelf="center" {...props} />
)

const ANIMATION_DURATION = 0.2

export const ClientsGrid = {
  mobile: { columns: 2, rows: 3 },
  tablet: { columns: 3, rows: 3 },
  desktop: isInPortfolio => ({
    columns: 5,
    rows: isInPortfolio ? 4 : 2,
  }),
}

export const SliderWrapper = props => (
  <Box
    {...props}
    sx={{
      ".slick-list": {
        width: ["100%", "100%", "100%", "100vw"],
        overflow: "visible",
      },
    }}
    mt={4}
  />
)

export const Grid = ({ isInPortfolio, ...props }) => (
  <Box
    sx={{
      display: "grid !important",
      gap: 3,
      justifyContent: "center",
      gridTemplate: [
        `repeat(${ClientsGrid.mobile.rows}, minmax(100px, 1fr)) / repeat(${ClientsGrid.mobile.columns}, minmax(100px, 1fr))`,
        `repeat(${ClientsGrid.tablet.rows}, minmax(120px, 1fr)) / repeat(${ClientsGrid.tablet.columns}, minmax(120px, 1fr))`,
        `repeat(${
          ClientsGrid.desktop(isInPortfolio).rows
        }, minmax(140px, 1fr)) / repeat(${
          ClientsGrid.desktop(isInPortfolio).columns
        }, minmax(140px, 1fr))`,
      ],
      maxWidth: 1200,
    }}
    {...props}
  />
)

export const ClientBackgroundWrapper = props => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 0,
      path: {
        fill: "transparent",
        transition: `fill ${ANIMATION_DURATION}s ease-in`,
      },
    }}
    className="background"
    {...props}
  />
)

export const Logo = props => (
  <Flex
    justifyContent="center"
    alignItems="center"
    sx={{
      position: "relative",
      "&:hover": {
        ".background": {
          path: {
            fill: props.clientColor,
          },
        },
        ".logo": {
          path: {
            fill: "white",
          },
        },
      },
    }}
    {...props}
  />
)

export const PortfolioLink = props => (
  <Link
    style={{
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    {...props}
  />
)

export const LogoSVGWrapper = ({ defaultIconColor = "grayBlue", ...props }) => (
  <Flex
    sx={{
      svg: {
        zIndex: 1,
        width: 100,
        maxHeight: 80,
        path: {
          transition: `fill ${ANIMATION_DURATION}s ease-in`,
          fill: '#fff',
        },
        "&:hover": {
          path: {
            fill: "white",
          },
        },
      },
    }}
    {...props}
  />
)
